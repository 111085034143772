import { useSync } from "@tldraw/sync";
import { useEffect, useState } from "react";
import {
  AssetRecordType,
  getHashForString,
  TLAssetStore,
  TLBookmarkAsset,
  Tldraw,
  uniqueId,
} from "tldraw";

const WORKER_URL = process.env.REACT_APP_WORKER_URL || `http://localhost:5050`;
console.log("REACT_APP_WORKER_URL", process.env.REACT_APP_WORKER_URL);
console.log("WORKER_URL", WORKER_URL);


function App() {
  const [roomId, setRoomId] = useState("test-room");
  const [username, setUsername] = useState("DefaultUsername"); // Default username

  // Listen for roomId and username from the parent
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) {
        console.warn("Received message from untrusted origin:", event.origin);
        return;
      }

      const { roomId: receivedRoomId, username: receivedUsername } = event.data;
      if (receivedRoomId) {
        setRoomId(receivedRoomId);
      }
      if (receivedUsername) {
        setUsername(receivedUsername);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  // Create a store connected to multiplayer with the dynamic roomId
  const store = useSync({
    uri: `${WORKER_URL}/connect/${roomId}`,
    assets: multiplayerAssets,
  });

  return (
    <div style={{ position: "fixed", inset: 0 }}>
      <Tldraw
        store={store}
        onMount={(editor) => {
          // @ts-expect-error
          window.editor = editor;
			
          editor.registerExternalAssetHandler("url", unfurlBookmarkUrl);
        }}
      />
    </div>
  );
}

// How does our server handle assets like images and videos?
const multiplayerAssets: TLAssetStore = {
  async upload(_asset, file) {
    const id = uniqueId();
    const objectName = `${id}-${file.name}`;
    const url = `${WORKER_URL}/uploads/${encodeURIComponent(objectName)}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      body: file,
    });

    if (!response.ok) {
      throw new Error(`Failed to upload asset: ${response.statusText}`);
    }

    return url;
  },
  resolve(asset) {
    return asset.props.src;
  },
};

// How does our server handle bookmark unfurling?
async function unfurlBookmarkUrl({
  url,
}: {
  url: string;
}): Promise<TLBookmarkAsset> {
  const asset: TLBookmarkAsset = {
    id: AssetRecordType.createId(getHashForString(url)),
    typeName: "asset",
    type: "bookmark",
    meta: {},
    props: {
      src: url,
      description: "",
      image: "",
      favicon: "",
      title: "",
    },
  };

  try {
    const response = await fetch(
      `${WORKER_URL}/unfurl?url=${encodeURIComponent(url)}`
    );
    const data = await response.json();

    asset.props.description = data?.description ?? "";
    asset.props.image = data?.image ?? "";
    asset.props.favicon = data?.favicon ?? "";
    asset.props.title = data?.title ?? "";
  } catch (e) {
    console.error(e);
  }

  return asset;
}

export default App;
